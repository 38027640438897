<template>
  <div class="error-404">
    <div class="error-img">
      <img src="@/assets/404.png" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.error-404 {
  background: #f2f2f2;
  height: 100%;
  .error-img {
    padding-top: 100px;
  }
  img {
    width: 493px;
    height: 363px;
  }
}
</style>
